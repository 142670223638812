import React from 'react';

import '../scss/tripItem.scss';

const Trip = ({ intl, map, title, body, link, duration, imgs, className, id }) => {
	return (
		<div className={`trip col col--2 ${className}`} id={id}>
			{map && <img className="trip-map" src={map} />}
			<div className="tripDetails">
				<span className="duration">{duration}</span>
				<h3>{title}</h3>
				{body}
			</div>
		</div>
	)
}

export default Trip;
